import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import RequestForm from "../Request";
import { Helmet } from "react-helmet";

// IMAGES IMPORTATION 
import PhoneWithClip from "../img/amazonclip.webp";
import ControllerImage from "../img/controllerplus.webp";
import ScrollDown from "../img/scroll-down.webp";
import TabletImage from "../img/tabletsa.webp";
import HeadsetImage from "../img/headset.webp";

// CATEGORY IMAGES
import ActionImage from "../img/category/action.webp";
import AdventureImage from "../img/category/adventure.webp";
import ArcadeImage from "../img/category/arcade.webp";
import BoardImage from "../img/category/board.webp";
import CasualImage from "../img/category/casual.webp";
import EducationImage from "../img/category/education.webp";
import MusicImage from "../img/category/music.webp";
import RacingImage from "../img/category/race.webp";
import SimulationImage from "../img/category/simulation.webp";
import SportImage from "../img/category/sport.webp";
import StrategyImage from "../img/category/strategy.webp";
import ShootingImage from "../img/category/shooting.webp";


function Discover() {
  const [latestGame, setLatestGame] = useState([]);
  const [recomandGame, setRecomandGame] = useState([]);
  const [goatGame, setGoatGame] = useState([]);
  const [controllerGame, setControllerGame] = useState([]);
  const [vrGame, setVrGame] = useState([]);
  const [tabletGame, setTabletGame] = useState([]);

  // Get DATA FROM DATABASE SELECT EVERYTHING
  useEffect(() => {
    Axios.get("https://server.friendlyapk.com/api/latest").then((res) => {
      setLatestGame(res.data);
    });
    Axios.get("https://server.friendlyapk.com/api/recomanded6").then((res) => {
      setRecomandGame(res.data);
    });
    Axios.get("https://server.friendlyapk.com/api/goat6").then((res) => {
      setGoatGame(res.data);
    });
    Axios.get("https://server.friendlyapk.com/api/controller4").then((res) => {
      setControllerGame(res.data);
    });
    Axios.get("https://server.friendlyapk.com/api/vr4").then((res) => {
      setVrGame(res.data);
    });
    Axios.get("https://server.friendlyapk.com/api/tablet4").then((res) => {
      setTabletGame(res.data);
    });
  }, []);

  return (
    <main className="Discover">
      <Helmet>
        <title>Friendlyapk</title>
        <meta name="description" content="friendlyapk.com is a controller or gamepad, Vr and Tablet friendly library of smartphone games. " />
      </Helmet>

      <section className="Heading">
        <div>
        <h2 className="h2">Find Android Games Supported by Controller, tablet & VR headset</h2>
        <p>Friendlyapk.com is a controller or gamepad, Vr and Tablet friendly library of android games. Find More games that you can play with controller. Find Games that fit perfect on any tablet. Find android VR games.</p>
        <p>
          <Link to="/pages/controller" className="btn1">
              Controller Games
            </Link>
          </p> 
        </div>
         <img src={PhoneWithClip} alt="Mobile Controller Game " className="gimg" />
      </section>
    <a href="#scrollDown"><img src={ScrollDown} id="scrollDown" className="scrolldown" alt="scroll down" title="scroll donw" /></a>  
      <section className="HeadingPlate" >
        <Link to="../pages/latestgame" className="recentgames">
          <h2>Latest Games</h2>
          <p>Discover all the latest games with support of controller, VR or tablet.</p>
        </Link>
        <div className="display">
          <Link to="../pages/VR" className="vrgames">
            <h2>VR Games</h2>
          </Link>
          <Link to="../pages/Controller" className="controllergames">
            <h2>Controller Games</h2>
          </Link>

          <Link to="../pages/Tablet" className="tabletgames">
            <h2>Tablet Games</h2>
          </Link>
          <Link to="../pages/goat" className="topgames">
            <h2>G.O.A.T</h2>
          </Link>
        </div>
      </section>

      <section className="goat">
        <div className="affiliate">
          <div>
            <img className="gimg" src={ControllerImage} alt="Controller games" title="Photograph: Microsoft" />
          </div>

          <div>
            <h3 className="h2"> Best Controller For You to Buy</h3>
            <p>
              Mobile gaming has never been more popular. The latest phones can run demanding, graphically impressive titles, so ports of popular PC and console games are increasingly common, but they
              are not always fun to play with touchscreen controls. What you need is a mobile game controller. We tested several of the top smartphone controllers by playing various games for hours at
              a time to find the very best performers.
            </p>
            <Link to="/pages/affiliate/controller" className="btn1">
              Controller Deals
            </Link>
          </div>
        </div>
        <h2 className="h2">Controller Games</h2>
        <div className="CardGrid">
          {controllerGame.map((value) => {
            return (
              <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
                <div className="Card">
                  <img src={`${value.iconname}`} alt={`${value.name}`} title={` Photograph by ${value.publisher}`} />
                  <h3>{value.name}</h3>
                </div>
              </Link>
            );
          })}
        </div>
        <Link to="/pages/controller" className="btn1">
          Controller Games
        </Link>
      </section>

      <section className="LatestGame">
        <h2 className="h2">Latest Game</h2>
        <div className="CardGrid">
          {latestGame.map((value) => {
            return (
              <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
                <div className="Card">
                  <img src={`${value.iconname}`} alt={`${value.name}`} title={` Photograph by ${value.publisher}`} />
                  <div>
                    <h3>{value.name}</h3>
                    <p>
                      <span>{value.cost}</span>
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <Link to="/pages/latestgame" className="btn1">
          Explore New Games
        </Link>
      </section>

      <section className="goat">
        <div className="affiliate">
          <div>
            <img className="gimg" src={HeadsetImage} alt="VR games" />
          </div>
          <div>
            <h3 className="h2">Best Mobile VR HeadSet Experience to have</h3>
            <p>
              Smartphone virtual reality headsets include several high-profile brands in the VR space, such as the Samsung Gear, Google Daydream, and the Pansonite HMDs. In addition, there are many
              games that have the capability to run on VR. Checkout some Cheatest to Advanced VRs
            </p>
            <Link to="/pages/affiliate/vr" className="btn1">
              VR Deals
            </Link>
          </div>
        </div>

        <h2 className="h2">VR Games</h2>
        <div className="CardGrid">
          {vrGame.map((value) => {
            return (
              <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
                <div className="Card">
                  <img src={`${value.iconname}`} alt={`${value.name}`} title={` Photograph by ${value.publisher}`} />
                  <h3>{value.name}</h3>
                </div>
              </Link>
            );
          })}
        </div>
        <Link to="/pages/vr" className="btn1">
          VR Games
        </Link>
      </section>

      <section className="LatestGame">
        <h2 className="h2">Editor Recomandation </h2>
        <div className="CardGrid">
          {recomandGame.map((value) => {
            return (
              <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
                <div className="Card">
                  <img src={`${value.iconname}`} alt={`${value.name}`} title={` Photograph by ${value.publisher}`} />
                  <div>
                    <h3>{value.name}</h3>
                    <p>
                      <span>{value.cost}</span>
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <Link to="../pages/recomanded" className="btn1">
          Editor Choise
        </Link>
      </section>

      <section className="goat">
        <div className="affiliate">
          <div>
            <img className="gimg" src={TabletImage} alt="Tablet Game" title="Photograph:Samsung" />
          </div>
          <div>
            <h3 className="h2">Best Tablet to Buy for Gaming!</h3>
            <p>
              Based on our research people never buy a tablet just to play games on it. That might be the primary reason, especially if you have a stressful day, you just want to sit back, open your
              favorite game, and forget about the real world. Tablet is portable gaming TV on the go. Connect controller to it and have home gaming vibe anywhere you like.
            </p>
            <Link to="../pages/affiliate/tablet" className="btn1">
              Tablet Deals
            </Link>
          </div>
        </div>
        <h2 className="h2">Perfect Tablet Games</h2>
        <div className="CardGrid">
          {tabletGame.map((value) => {
            return (
              <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
                <div className="Card">
                  <img src={`${value.iconname}`} alt={`${value.name}`} title={` Photograph by ${value.publisher}`} />
                  <h3>{value.name}</h3>
                </div>
              </Link>
            );
          })}
        </div>
        <Link to="/pages/tablet" className="btn1">
          Tablet Games
        </Link>
      </section>

      <section className="categorySection">
        <h2 className="h2">Browse by Category</h2>
        <div className="CardGrid">
          <Link to="../pages/category/action">
            <div className="Card">
              <img src={ActionImage} alt="Action" title="Artist: - From FlatIcon" />
              <h3>Action</h3>
            </div>
          </Link>
          <Link to="../pages/category/adventure">
            <div className="Card">
              <img src={AdventureImage} alt="Adventure" title="Artist: - From FlatIcon" />
              <h3>Adventure</h3>
            </div>
          </Link>
          <Link to="../pages/category/arcade">
            <div className="Card">
              <img src={ArcadeImage} alt="Arcade" title="Artist: - From FlatIcon" />
              <h3>Arcade</h3>
            </div>
          </Link>
          <Link to="../pages/category/board">
            <div className="Card">
              <img src={BoardImage} alt="Board" title="Artist: - From FlatIcon" />
              <h3>Board</h3>
            </div>
          </Link>
          <Link to="../pages/category/casual">
            <div className="Card">
              <img src={CasualImage} alt="Casual" title="Artist: - From FlatIcon" />
              <h3>Casual</h3>
            </div>
          </Link>
          <Link to="../pages/category/education">
            <div className="Card">
              <img src={EducationImage} alt="Education" title="Artist: - From FlatIcon" />
              <h3>Education</h3>
            </div>
          </Link>
          <Link to="../pages/category/music">
            <div className="Card">
              <img src={MusicImage} alt="Music" title="Artist: - From FlatIcon" />
              <h3>Music</h3>
            </div>
          </Link>
          <Link to="../pages/category/racing">
            <div className="Card">
              <img src={RacingImage} alt="Racing" title="Artist: - From FlatIcon" />
              <h3>Racing</h3>
            </div>
          </Link>
          <Link to="../pages/category/simulator">
            <div className="Card">
              <img src={SimulationImage} alt="Simulation" title="Artist: - From FlatIcon" />
              <h3>Simulation</h3>
            </div>
          </Link>
          <Link to="../pages/category/sport">
            <div className="Card">
              <img src={SportImage} alt="Sport" title="Artist: - From FlatIcon" />
              <h3>Sport</h3>
            </div>
          </Link>
          <Link to="../pages/category/strategy">
            <div className="Card">
              <img src={StrategyImage} alt="Strategy" title="Artist: - From FlatIcon" />
              <h3>Strategy</h3>
            </div>
          </Link>
          <Link to="../pages/category/shooting">
            <div className="Card">
              <img src={ShootingImage} alt="Shooting" title="Artist: - From FlatIcon" />
              <h3>Shooting</h3>
            </div>
          </Link>
        </div>
      </section>
      <Link to="/pages/category" className="btn1">
        Categories
      </Link>

      <section className="goat">
        <div className="affiliate">
          <div>
            <img className="gimg" src={PhoneWithClip} alt="Tablet Game" title="Photograph:Samsung" />
          </div>
          <div>
            <h3 className="h2">Advance Accessories</h3>
            <p>
              Jump into mobile gaming with this expertly engineered accessories for XBox controller, PS5 Controller, GamePad gadgets, clips, wraps, utility & other accessories. Learn about all your
              needs that can help you to perform at your best with amazing tools and gadgets listed here. Checkout best budget friendly, durable, cool looking & useful gadget for gaming, tablets and
              VR's
            </p>
            <Link to="../pages/affiliate/accessories" className="btn1">
              Accessories Deals
            </Link>
          </div>
        </div>
      </section>

      <section className="goat">
        <div>
          <Link to="../pages/goat">
<svg version="1.1" id="RedSkull" viewBox="0 0 512 512" >
<g>
	<path className="st0" d="M80.5,16.5c26.3-2.1,51.6,1.8,76,11.5c-20.6-0.6-40.3,3.4-59,12c-27,15.9-35.1,38.8-24.5,68.5
		c8.4,17.4,21.3,30.6,38.5,39.5c6.9,3.6,13.9,6.9,21,10c2.8,3,3.6,6.5,2.5,10.5c-4,17.5-14.5,29.2-31.5,35
		c-23.3-10.2-43.8-24.6-61.5-43C15,131.3,8.7,98,23,60.5C34.2,35.4,53.4,20.8,80.5,16.5z"/>
</g>
<g>
	<path className="st0" d="M398.5,16.5c12.7-0.4,25.4,0.1,38,1.5c34.9,8.9,54.1,31.4,57.5,67.5c3.4,23.6-1.9,44.9-16,64
		c-19.2,23.7-42.7,41.7-70.5,54c-20-6.9-30.5-20.9-31.5-42c0.5-1.4,1.3-2.6,2.5-3.5c20.3-7.5,37.4-19.4,51.5-35.5
		c14.1-19.5,16.1-40.2,6-62c-5.6-8.9-13.1-15.8-22.5-20.5c-18.7-8.6-38.4-12.6-59-12C368.8,22.3,383.5,18.4,398.5,16.5z"/>
</g>
<path className="st1" d="M411,345.5c12.5-28,17.5-57.4,15-88c-1-13.9-4.1-27.3-9.5-40c-4.5,1.9-9.2,2.4-14,1.5
	c-25.1-9.4-39.4-27.4-43-54c0.4-8.1,3.7-14.8,10-20c-36.8-32.1-79.8-46.6-129-43.5c-37.6,4.1-70.6,18.6-99,43.5
	c6.3,5.2,9.6,11.9,10,20c-3,23-14.6,40-35,51c-7.1,3.9-14.5,4.4-22,1.5c-3.1,6.3-5.2,13-6.5,20c-7.4,37.3-3.4,73.3,12,108
	c0.8,4.4,0.5,8.8-1,13c-4.4,5.8-8.1,12.2-11,19c-6.8,20-3.8,38.3,9,55c15.4,15.9,34.3,22.5,56.5,20c2.6-18.4-4.7-30.7-22-37
	c-0.8-1-1.7-2-2.5-3c-1.7-6.3,0.6-10,7-11c16.2,4.5,26.9,14.9,32,31c1.3,16.3,1.9,32.6,2,49c1.4,6.4,5.3,10.6,11.5,12.5
	c5,0.5,10,0.7,15,0.5c-0.2-9.7,0-19.3,0.5-29c6-6.8,11-6.1,15,2c0.5,9,0.7,18,0.5,27c11.7,0,23.3,0,35,0c-0.2-9.3,0-18.7,0.5-28
	c5-8,10-8,15,0c0.5,9.3,0.7,18.7,0.5,28c11.7,0,23.3,0,35,0c-0.2-9.3,0-18.7,0.5-28c4.6-7.3,9.6-7.6,15-1c0.5,9.7,0.7,19.3,0.5,29
	c5,0.2,10,0,15-0.5c6.2-1.9,10.1-6.1,11.5-12.5c0.1-16.4,0.7-32.7,2-49c4.2-13.5,12.7-23.4,25.5-29.5c4.6-2.4,8.7-1.9,12.5,1.5
	c2.5,4.6,1.7,8.4-2.5,11.5c-16.6,6.4-23.6,18.6-21,36.5c22.2,2.5,41.1-4.1,56.5-20c14.6-19.9,16.3-40.9,5-63
	C412.3,362.8,409.7,354.8,411,345.5z M123.5,276c-1.9,2.3-3.4,4.8-4.5,7.5c-0.1,3.9-0.9,7.5-2.5,11c-5.2,4.4-9.7,3.7-13.5-2
	c-0.6-21.2,9.6-33.6,30.5-37c9.3,1,12.2,5.7,8.5,14C135.7,271.4,129.6,273.6,123.5,276z M197.5,382c-28.5,3.5-48-8-58.5-34.5
	c-4.3-14.2-2.8-27.7,4.5-40.5c-4.9,0.1-9.4-1-13.5-3.5c-2.5-5.1-1.7-9.4,2.5-13c13.9-0.8,27.5,0.4,41,3.5c4.3,1.5,8.3,3.5,12,6
	c6.4,6,13,11.7,20,17c9.1,4.7,18.8,7.7,29,9c0.8,0.8,1.7,1.7,2.5,2.5C237.7,356.5,224.5,374.3,197.5,382z M267.5,424
	c-3.8-6-7.8-11.8-12-17.5c-4.3,6.5-9,12.6-14,18.5c-8.2,1.2-11.7-2.3-10.5-10.5c6.7-10.5,13.8-20.5,21.5-30c3.7-0.5,6.9,0.5,9.5,3
	c5.8,9.2,11.8,18.2,18,27C280.7,424,276.6,427.2,267.5,424z M380.5,304c-4.1,2-8.4,3-13,3c13,28,7,51-18,69
	c-27.4,12.9-50.2,7.4-68.5-16.5c-6-9.5-8.3-19.9-7-31c1.2-1.5,2.7-2.7,4.5-3.5c17.5-1.2,31.8-8.6,43-22c9-6.7,19-10.9,30-12.5
	c9-0.2,18,0,27,0.5C383,294.7,383.6,299,380.5,304z M408,292.5c-3,5-7.2,6.2-12.5,3.5c-1.9-6.3-4.3-12.5-7-18.5
	c-1.5-1-2.8-2.1-4-3.5c-5.4-0.8-10.6-2.3-15.5-4.5c-3.5-7.3-1.4-11.9,6.5-14C397.5,258,408.4,270.4,408,292.5z"/>
<g>
	<path className="st2" d="M162.5,307.5c11.4,2.9,20.8,9.1,28,18.5c8.5,6.8,18.2,11.1,29,13c-0.4,9.4-4.7,16.7-13,22
		c-19.8,10.1-36.3,6.3-49.5-11.5C149.8,334.3,151.7,320.3,162.5,307.5z"/>
</g>
<g>
	<path className="st2" d="M347.5,307.5c10.5,10.8,13.4,23.5,8.5,38c-8.2,16.9-21.7,23.7-40.5,20.5c-13.8-3.8-22-12.7-24.5-26.5
		c16.2-3.7,29.7-11.8,40.5-24.5C336.5,311.6,341.9,309.1,347.5,307.5z"/>
</g>
</svg>
            <h2 className="h2">G.O.A.T Games</h2>
          </Link>
        </div>
        <div className="CardGrid">
          {goatGame.map((value) => {
            return (
              <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
                <div className="Card">
                  <img src={`${value.iconname}`} alt={`${value.name}`} title={` Photograph by ${value.publisher}`} />
                  <h3>{value.name}</h3>
                </div>
              </Link>
            );
          })}
        </div>
        <Link to="../pages/goat" className="btn1">
              G.O.A.T Games
            </Link>
      </section>

      <RequestForm />
    </main>
  );
}
export default Discover;
