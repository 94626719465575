import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Axios from "axios";
import RequestForm from "../Request";
import TheEnd from "./TheEnd";

function Affiliate() {
  const { device } = useParams();
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    Axios.get(`https://server.friendlyapk.com/api/affiliate/${device}`).then((response) => {
      setDevices(response.data);
    });
  }, []);
  return (
    <main>
      <Helmet>
        <title>Friendlyapk - {device} gadget</title>
        <meta name={`description" content="Get best deals on ${device}, affiliate links!`} />
        <meta name="keywords" content="aboutus, learn more, contact us" />
      </Helmet>
      {devices.map((value) => {
        return (
          <section className="affiliate" key={value.id}>
            <div>
              <img src={value.deviceimg} alt={value.name} title={`Photograph by ${value.company}`} className="gimg2" />
            </div>
            <div>
              <h3 className="h2">{value.name}</h3>
              <p>{value.company}</p>
              <p>
                {value.description}
              </p>
              <p><b>{value.support}</b></p>

              <a href={value.amzlink} target="_blank" rel="noreferrer noopener" className="btn1">
                Buy Now
              </a>
            </div>
          </section>
        );
      })}
      <TheEnd />
      <RequestForm />
    </main>
  );
}
export default Affiliate;
