import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import RequestForm from "../Request";
import TheEnd from "./TheEnd";
import DOMPurify from "dompurify";

function LatestGame() {
  const [allGames, setAllGames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    Axios.get("https://server.friendlyapk.com/api/get").then((res) => {
      setAllGames(res.data);
    });
  }, []);

  const searchQuery = (e) => {
    DOMPurify.sanitize(searchTerm);
    if(searchTerm !== "" || searchTerm !== " "){
    Axios.get(`https://server.friendlyapk.com/api/searchall/${searchTerm}`).then((res) => {
      setAllGames(res.data);
    })
  } 
    e.preventDefault();
  };
  const searchReset = (e) => {
    Axios.get("https://server.friendlyapk.com/api/get").then((res) => {
      setAllGames(res.data);
    });
  }

  

  return (
    <main className="LatestGamePage">
      <h2 className="h2page">Latest Games</h2>
      <div action="../pages/latestgame" className="searchBox">
        <input type="text" onChange={(e) => {
          setSearchTerm(e.target.value);
        }} />
          <img src="https://i.ibb.co/2tV74wN/search.png" alt="Search button" onClick={searchQuery} />        
        <img src="https://i.ibb.co/yhRbSg4/refresh.png" onClick={searchReset} alt="Reset Buttun" />
      </div>

      <div className="CardGrid">
        {allGames.map((value) => {
          return (
            <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
              <div className="Card">
                <img src={`${value.iconname}`} alt={`${value.name}`} title={`Photograph by ${value.publisher}`} />
                <div>
                  <h3>{value.name}</h3>
                  <p>
                    <span>{value.cost}</span>
                  </p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <h3 className="h3">THE END</h3>
      <TheEnd />
      <RequestForm />
    </main>
  );
}
export default LatestGame;
