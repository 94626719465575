import React, { useState } from "react";
import "./App.css";
import Axios from "axios";
import { Link } from "react-router-dom";

function ContactForm() {
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [thanks, setThanks] = useState(false);

  const submitContact = (event) => {
    if (contactName !== "") {
      if (contactEmail !== "") {
        Axios.post("http://server.friendlyapk.com/contactform", {
          contactName: contactName,
          contactEmail: contactEmail,
          contactSubject: contactSubject,
          contactMessage: contactMessage,
        }).then(() => {
          setThanks(true);
        });
        event.preventDefault();
        event.target.reset();
      } else {
        event.preventDefault();
      }
    } else {
      event.preventDefault();
    }
  };

  return (
    <>
    <h2 className="h2">Contact Us</h2>
      <form className="contactForm" onSubmit={submitContact} action="../contactForm">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          id=""
          onChange={(e) => {
            setContactName(e.target.value);
          }}
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id=""
          onChange={(e) => {
            setContactEmail(e.target.value);
          }}
        />

        <label htmlFor="subject">Subject</label>
        <input
          type="text"
          name="subject"
          id=""
          onChange={(e) => {
            setContactSubject(e.target.value);
          }}
        />

        <label htmlFor="message">Message</label>
        <textarea
          name="message"
          id=""
          cols="30"
          rows="10"
          onChange={(e) => {
            setContactMessage(e.target.value);
          }}
          label="Message"
        />
        <p> * We do not store any of your persoanl data on our site. Email and name is optional and to notify you, if your game is added to the site or not for given reason.</p>
        <p>
          
          * By submiting this form you agree to acknowlegde and accept our all <Link to="../pages/privacy">privacy</Link>, <Link to="../pages/disclaimer"> disclaimers </Link> ,
          <Link to="../pages/termsconditions"> terms & conditions </Link> .
        </p>

        <button type="submit">Say Hello</button>
      </form>
      {thanks ? (
        <div className="alertbox" onClick={() => {
          setThanks(false);
        }}>
        <div>
          <p>We will be in touch in 2 weeks!</p>
          <button
            className="btn1"
            onClick={() => {
              setThanks(false);
            }}
          >
            Okay
          </button>
        </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
export default ContactForm;
