import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import RequestForm from "../Request";
import TheEnd from "./TheEnd";

function Recomanded () {

  const [recomandedGame, setRecomandedGame] = useState([]);

    useEffect(() => {
        Axios.get('https://server.friendlyapk.com/api/recomanded').then((res) => {
          setRecomandedGame(res.data);
        });
      }, []);
    return(
      <main>
    <h2 className="h2page">Editor's Choise</h2>
        <div className="CardGrid">
          {recomandedGame.map((value) => {
            return (
              <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
                <div className="Card">
                  <img src={`${value.iconname}`} alt={`${value.name}`} title={`Photograph by ${value.publisher}`} />
                  <div>
                    <h3>{value.name}</h3>
                    <p>
                      <span>{value.cost}</span>
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <TheEnd />
        <RequestForm />
      </main>
        
    )
}
export default Recomanded;
