import React from "react";
import { Link } from "react-router-dom";

export function Ops404() {
    return(
        <div className="Ops404">
            <img src="https://i.ibb.co/4dXB9jB/404-min.png" alt="404 Error" />
            <Link to="../" className="btn1">Go Home</Link>
        </div>
    )
}
export default Ops404;
