import React from 'react'
import Giphy from "../img/giphy.webp";
import theend from "../img/the-end.webp"
function TheEnd() {
  return (
    <div className="theEnd">
      <img src={theend} alt="The End" title="Art by FlatIcon.com Artist" />
      <img src={Giphy} alt="Crackers" title="Art by FlatIcon.com Artist"  />
      </div>
  )
}

export default TheEnd