import React from 'react'
import { Link } from 'react-router-dom';
import RequestForm from '../Request';

function QA() {
 

return(
  <main className='QA'>
    <img src="https://i.ibb.co/y8vTWMk/FAQs-pana.png" className="faqheader" alt="FAQ" />
    <section>
    <h3 className='Question'>Introduction</h3>
    <p className='Answer'>Welcome to Q and A of apkfriendly.com. This website is for people looking android and ios games that can be played by controller, gamepads or vr headsets!  </p>
    </section>

    <section>
    <h3 className='Question'>How to find games support by controllers?</h3>
    <p className='Answer'> 1. Go To Home Pages and click on controller games on top right corner or <Link to="../pages/controller">Click Here!</Link> </p>
    <p className='Answer'>2. Go To Navigation Bar  &#9776; and click on Controller games! or <Link to="../pages/controller">Click Here!</Link> </p>
    </section>

    <section>
    <h3 className='Question'>How to find games support by VR?</h3>
    <p className='Answer'> 1. Go To Home Pages and click on VR games on top left corner or <Link to="../pages/vr">Click Here!</Link> </p>
    <p className='Answer'>2. Go To Navigation Bar  &#9776; and click on VR games! or <Link to="../pages/vr">Click Here!</Link> </p>
    </section>

    <section>
      <h3 className='Question'>How to contact Us?</h3>
      <p className='Answer'> Go To Navigation Bar  &#9776; and click on <b> About Us. </b> Go To Bottom of page and find <b>Contact</b> section form and fill form or <Link to="../pages/about">Click Here!</Link>.</p>
      
    </section>

    <section>
      <h3 className='Question'>How to fill Contact Form?</h3>
      <p className='Answer'>Follow these simple steps to fill form!</p>
      <p className='Answer'>1. Write your full name on label 'Name'!</p>
      <p className='Answer'>2. Write your Personal Email on label 'Email'!</p>
      <p className='Answer'>3. Write Simple explaination of your topic of choice on label 'Subject'. What is it about you want to talk to Us.</p>
      <p className='Answer'>4. Write simple paragraph to explain what you want to explain on label 'Message'!</p>
      <p className='Answer'>5. At Last Click on button 'Say Hello!' And Your form will be Submited</p>
    </section>

    <section>
      <h3 className='Question'>How to Request Your Favorite Game?</h3>
      <p className='Answer'> Find 'Request your favorite Game' section on bottom of most pages and fill form to request your favorite game.</p>
    </section>

    <section>
      <h3 className='Question'>How to fill Request Form?</h3>
      <p className='Answer'>Follow these simple steps to fill form!</p>
      <p className='Answer'>1. Write Game name your want to add to website on label 'Game Name'</p>
      <p className='Answer'>2. Copy and paste URL of game from google play store by share button or You can ignore this field!</p>
      <p className='Answer'>3.Write your full name on label 'Name'!</p>
      <p className='Answer'>4.Write your Personal Email on label 'Email'!</p>
      <p className='Answer'>5. At Last Click on button 'Add My Game!' And Your form will be Submited</p>
    </section>

    <RequestForm />
  </main>
)

  
}

export default QA;
