import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import RequestForm from "../Request";
import { Helmet } from "react-helmet";
import TheEnd from "./TheEnd";
import DOMPurify from "dompurify";

function Controller() {
  const [controllerGames, setControllerGames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    Axios.get(`https://server.friendlyapk.com/api/controller`).then((res) => {
      setControllerGames(res.data);
    });
  }, []);

  const searchQuery = (e) => {
        DOMPurify.sanitize(searchTerm);
    if (searchTerm !== "" || searchTerm !== " ") {
      Axios.get(`https://server.friendlyapk.com/api/controller/${searchTerm}`).then((res) => {
        setControllerGames(res.data);
      });
    }
    e.preventDefault();
  };
  const searchReset = (e) => {
    Axios.get(`https://server.friendlyapk.com/api/controller`).then((res) => {
      setControllerGames(res.data);
    });
  };

  return (
    <main className="ControllerPage">
      <Helmet>
        <title>Controller Games</title>
        <meta name="description" content="List of all games that supports and allow Controller & Gamepad compatebility" />
      </Helmet>
      <h2 className="h2page">Controller Supported Games</h2>

      <div action="../pages/latestgame" className="searchBox">
        <input
          type="text"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <img src="https://i.ibb.co/2tV74wN/search.png" alt="Search button" onClick={searchQuery} />
        <img src="https://i.ibb.co/yhRbSg4/refresh.png" onClick={searchReset} alt="Reset Buttun" />
      </div>

      <div className="CardGrid">
        {controllerGames.map((value) => {
          return (
            <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
              <div className="Card">
                <img src={`${value.iconname}`} alt={`${value.iconname}`} title={`Photograph by ${value.publisher}`} />
                <h3>{value.name}</h3>
              </div>
            </Link>
          );
        })}
      </div>
      <TheEnd />
      <RequestForm />
    </main>
  );
}
export default Controller;
