//  IMPORT COMPONENTS
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// CSS IMPORTS ....
import "./App.css";

//   ASSETS
import { LoginContext } from "./Context";

//   PAGES & FORMS
import Header from "./pages/Header";
import Discover from "./pages/Discover";
import Controller from "./pages/Controller";
import Tablet from "./pages/Tablet";
import VR from "./pages/VR";
import Recomanded from "./pages/Recomanded";
import GOAT from "./pages/GOAT";
import Category from "./pages/Category";
import CategoryList from "./pages/CategoryList";
import About from "./pages/About";
import Ops404 from "./pages/Ops404";
import Footer from "./pages/Footer";
import ContactForm from "./Contact";
import LatestGame from "./pages/LatestGames";
import GameFullPage from "./pages/GameFullPage";
import Free from "./pages/Free";
import Affiliate from "./pages/Affiliate";
import ScrollToTop from "./ScrollToTop";
import QA from "./pages/QA";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Disclaimer from "./pages/Disclaimer";
function App() {

    const [login, setLogin] = useState(false);

  return (
    <LoginContext.Provider value={{login, setLogin}} className="App">
      <ScrollToTop />
      <div className="pattern">

      </div>
      <Header />
      <Routes>
        <Route path="/" element={<Discover />} />
        <Route path="/pages/vr" element={<VR />} />
        <Route path="/pages/controller" element={<Controller />} />
        <Route path="/pages/tablet" element={<Tablet />} />
        <Route path="/pages/recomanded" element={<Recomanded />} />
        <Route path="/pages/goat" element={<GOAT />} />
        <Route path="/pages/category" element={<Category />} />
        <Route path="/pages/about" element={<About />} />
        <Route path="/pages/free" element={<Free />} />
        <Route path="/pages/faq" element={ <QA />} />
        <Route path="/pages/termsconditions" element={ <Terms />} />
        <Route path="/pages/privacy" element={ <Privacy />} />
        <Route path="/pages/disclaimer" element={ <Disclaimer />} />
        <Route path="*" element={<Ops404 />} />
        <Route path="/contactform" element={<ContactForm />} />
        <Route path="/pages/latestgame" element={<LatestGame />} />
        <Route path="/pages/fullpage/:id" element={<GameFullPage />} />
        <Route path="/pages/category/:category" element={<CategoryList />} />
        <Route path="/pages/affiliate/:device" element={<Affiliate />} />
      </Routes>

      <Footer />
    </LoginContext.Provider>
  );
}

export default App;
