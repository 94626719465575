import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Link } from "react-router-dom";
import RequestForm from "../Request";
import { Helmet } from "react-helmet";  
import TheEnd from "./TheEnd";

export function CategoryList() {
  const { category } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const [categoryImg, setCategoryImg] = useState([]);
  // Get DATA FROM DATABASE SELECT EVERYTHING
  useEffect(() => {
    Axios.get(`https://server.friendlyapk.com/api/category/${category}`).then((response) => {
      setCategoryList(response.data);
    });
  }, []);
  return (
    <main className="CategoryList" onLoad={() => {
        switch (category) {
          case "action":
            setCategoryImg("https://i.ibb.co/fk2y9PX/action.png");
            break; 
          case "adventure":
            setCategoryImg("https://i.ibb.co/FDvGJMX/Adventure.png");
            break; 
          case "arcade":
            setCategoryImg("https://i.ibb.co/3rY5FwF/arcade.png");
            break; 
          case "board":
            setCategoryImg("https://i.ibb.co/JBdDvt8/board.png");
            break; 
          case "casual":
            setCategoryImg("https://i.ibb.co/mDpBnVR/casual.png");
            break; 
          case "education":
            setCategoryImg("https://i.ibb.co/NWwSnc7/education.png");
            break; 
          case "music":
            setCategoryImg("https://i.ibb.co/9ZD0DJ3/music.png");
            break; 
          case "racing":
            setCategoryImg("https://i.ibb.co/NLn8Ryt/race.png");
            break; 
          case "simulation":
            setCategoryImg("https://i.ibb.co/wpPCk03/simulation.png");
            break; 
          case "sport":
            setCategoryImg("https://i.ibb.co/whP29wP/Sport.png");
            break; 
          case "strategy":
            setCategoryImg("https://i.ibb.co/7XGrnZn/strategy.png");
            break; 
          case "shooting":
            setCategoryImg("https://i.ibb.co/4tD59X4/shooting.png");
            break; 

          default:
            break;
        }
      }}>
        <Helmet>
            <title>{category} Games  </title>
            <meta name="description" content={`Full list of all Game from ${category} category`} />
          </Helmet>
          <div className="pageshead">
            <img src={categoryImg} className="headimg" alt={category} title={category} />
            <h2 className="whiteh2"> Welcome To {category} Genre</h2>  
          </div>
      
      <div className="CardGrid">
        {categoryList.map((value) => {
          return (
            <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
              <div className="Card">
                <img src={`${value.iconname}`} alt={`${value.iconname}`} title={`Photograph by ${value.publisher}`} />
                <h3>{value.name}</h3>
              </div>
            </Link>
          );
        })}
      </div>
      <TheEnd />
      <RequestForm />
    </main>
  );
}
export default CategoryList;
