import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import RequestForm from "../Request";
import TheEnd from "./TheEnd";

function VR() {
  const [vrGames, setVRGames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    Axios.get("https://server.friendlyapk.com/api/vr").then((res) => {
      setVRGames(res.data);
    });
  }, []);

  const searchQuery = (e) => {
    if(searchTerm !== "" || searchTerm !== " "){
    Axios.get(`https://server.friendlyapk.com/api/vr/${searchTerm}`).then((res) => {
      setVRGames(res.data);
    })
  } 
    e.preventDefault();
  };
  const searchReset = () => {
    Axios.get("https://server.friendlyapk.com/api/vr").then((res) => {
      setVRGames(res.data);
    });
  }

  return(
  <main className="VRPage">
    <h2 className="h2page">Virtual Reality Games</h2>
    <div action="../pages/latestgame" className="searchBox">
        <input type="text" onChange={(e) => {
          setSearchTerm(e.target.value);
        }} />
          <img src="https://i.ibb.co/2tV74wN/search.png" alt="Search button" onClick={searchQuery} />        
        <img src="https://i.ibb.co/yhRbSg4/refresh.png" onClick={searchReset} alt="Reset Buttun" />
      </div>
    <div className="CardGrid">
          {vrGames.map((value) => {
            return (
            <Link to={`../pages/fullpage/${value.id}`} key={`${value.id}`}>
            <div className="Card">
              <img src={`${value.iconname}`} alt={`${value.name}`} title={`Photograph by ${value.publisher}`} />
              <h3>{value.name}</h3>
            </div>
          </Link>
            )
          })}
        </div>
    <TheEnd />
    <RequestForm />
  </main>
  )
}
export default VR;

