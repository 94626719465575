import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Link } from "react-router-dom";
import RequestForm from "../Request";
import { Helmet } from "react-helmet";
import TheEnd from "./TheEnd";

function GameFullPage() {
  const { id } = useParams();
  const [gameFullPage, setGameFullPage] = useState([]);
  // Get DATA FROM DATABASE SELECT EVERYTHING
  useEffect(() => {
    Axios.get(`https://server.friendlyapk.com/api/fullpage/${id}`).then((response) => {
      setGameFullPage(response.data);
    });
  }, []);

  return (
    <main className="GameFullPage">
      {gameFullPage.map((value) => {
        return (
          <div className="fullPage" key={value.id}>
            <Helmet>
              <title>{`${value.name}`}</title>
              <meta name="description" content={`${value.name} publish by ${value.publisher} & develop by ${value.developer} ${value.controllername} ${value.vrname} ${value.tabletname}`} />
              <meta name="keywords" content={`${value.name}, ${value.publisher}, ${value.developer}, ${value.controllername}, ${value.vrname}, ${value.tabletname}`} />
              <meta name="image" content={value.imagename} />
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:site" content="@friendlyapk.com" />
              <meta name="twitter:title" content={`${value.name}`} />
              <meta name="twitter:description" content="" />
              <meta name="twitter:creator" content="@friendlyapk" />
              <meta name="twitter:image" content={`${value.imagename}`} />
              <meta property="og:title" content={`${value.name}`} />
              <meta property="og:type" content="article" />
              <meta property="og:url" content="http://www.friendlyapk.com/" />
              <meta property="og:image" content={`${value.imagename}`} />
              <meta property="og:description" content={value.discription} />
              <meta property="og:site_name" content="friendlyapk.com" />

            </Helmet>
            <div className="yt" id="yt">
                <iframe
                  width="100%"
                  height="600px"
                  src={`https://www.youtube.com/embed/${value.vlink}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            
            <section>
              <div className="FullCard">
                <img src={value.iconname} alt={value.name} title={`Photograph by ${value.publisher}`} />
                <h2>{value.name}</h2>
              </div>
              <p>
                Developer: <span>{value.developer}</span> | Publisher: <span>{value.publisher}</span>
              </p>
              <p>
                <span className="btn2">
                  <span>{`$${value.cost}`}</span>
                </span>
                <a href={value.playlink} className="btn2" target="_blank" rel="noreferrer noopener">
                  <span>PlayStore</span>
                </a>
                <a href={value.applelink} className="btn2" target="_blank" rel="noreferrer noopener">
                  <span>Apple IOS Store</span>
                </a>
              </p>
              <div className="CategorySection">
                <h3>Categories & Genre</h3>
                <div className="CategoryDisplay">
                  <div className="CategoryCard">
                    <Link to={`../pages/category/${value.category1name}`}>
                      <img src={value.category1} alt={value.category1name} title="Icon by FlatIcons" />
                      <p>{value.category1name}</p>
                    </Link>
                  </div>
                  <div className="CategoryCard">
                    <Link to={`../pages/category/${value.category2name}`}>
                      <img src={value.category2} alt={value.category2name} title="Icon by FlatIcons" />
                      <p>{value.category2name}</p>
                    </Link>
                  </div>
                  <div className="CategoryCard">
                    <Link to={`../pages/category/${value.category3name}`}>
                      <img src={value.category3} alt={value.category3name} title="Icon by FlatIcons" />
                      <p>{value.category3name}</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="SupportedMedia">
                <h2 className="h2">Friendly Media</h2>
                <div className="FriendlyDisplay">
                  <div>
                    <Link to="../pages/controller">
                      <img src={value.controller} alt={value.controllername} title="Icon by Flaticon" />
                      <p>{value.controllername}</p>
                    </Link>
                  </div>
                  <div>
                    <Link to="../pages/tablet">
                      <img src={value.tablet} alt={value.tabletname} title="Icon by Flaticon" />
                      <p>{value.tabletname}</p>
                    </Link>
                  </div>
                  <div>
                    <Link to="../pages/vr">
                      <img src={value.vr} alt={value.vrname} title="Icon by Flaticon" />
                      <p>{value.vrname}</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="MoreInfo">
                <h2 className="h2">More Info</h2>
                <img src={value.age} alt="Age Rating" />
                <div className="score">
                  <p> <span>{value.score}</span> </p>
                  <p> Friendly Score </p>
                </div>
                <div className="androidversion">
                  <p>{value.android}</p>
                  <p> Android Required </p>
                </div>
              </div>
            <img className="headProfileImg" src={value.imagename} alt={value.name} title={`Photograph by ${value.publisher}`} />
              
            </section>
            <RequestForm />
            <TheEnd />
          </div>
        );
      })}
    </main>
  );
}
export default GameFullPage;
