import React from "react";
import ContactForm from "../Contact";
import RequestForm from "../Request";
import { Helmet } from "react-helmet";
import TheEnd from "./TheEnd";
import Collage from "../img/latestgames.webp";
export function About() {
  return (
    <main className="About">
      <Helmet>
        <title>Friendlyapk - about us</title>
        <meta name="description" content="Learn about who is website for, who build it and contact us for more info." />
        <meta name="keywords" content="aboutus, learn more, contact us" />
      </Helmet>
      <a href="https://storyset.com/business"> <img src={Collage} alt="About us header" /></a>
      <h2 className="h2">Get to Know Us</h2>
      <q>Let the Game Begin</q>
      <p>Let us😇 do the hard work and bring all the mobile games🏂 that can be played with a controller🎮 or gamepad while you install them in one click. Games🏊 that don't distort on tablet and games that work perfect on mobile VR. </p>
      <p>It started when the founder wanted to play games🚴 with his new controller🎮 and could not find a game he liked. The Play✨ Store is a huge place to find games and most of them don't support controllers🎮. Founder wastes his time to figure out which game is playable on controllers like the ps5 controller, xbox 🎮 etc. </p>
      <p>That's where we🙏🏽 come to test and choose the best games for you and save your time. All games are tried, tested and trusted❤️  by our editor's. </p>
      <a href="https://storyset.com/business"> <img src="https://i.ibb.co/YR3d5dv/Mobile-Marketing-bro-min.png" alt="Share Us" /> </a>
      <ContactForm />
      <RequestForm />
      <TheEnd />
    </main>
  );
}
export default About;
