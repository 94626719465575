import React from "react";
import { Link } from "react-router-dom";
import RequestForm from "../Request";
import { Helmet } from "react-helmet";  
import TheEnd from "./TheEnd";

// CATEGORY IMAGES
// CATEGORY IMAGES
import ActionImage from "../img/category/action.webp";
import AdventureImage from "../img/category/adventure.webp";
import ArcadeImage from "../img/category/arcade.webp";
import BoardImage from "../img/category/board.webp";
import CasualImage from "../img/category/casual.webp";
import EducationImage from "../img/category/education.webp";
import MusicImage from "../img/category/music.webp";
import RacingImage from "../img/category/race.webp";
import SimulationImage from "../img/category/simulation.webp";
import SportImage from "../img/category/sport.webp";
import StrategyImage from "../img/category/strategy.webp";
import ShootingImage from "../img/category/shooting.webp";

export function Category() {
    return(
        <main className="Category">
          <Helmet>
            <title>Android Games Categories </title>
            <meta name="description" content="Full list of all android games categories by friendlyapk" />
            <meta name="keywords" content="friendlyapk.com, android games, browse games by category." />
          </Helmet>
          
            <section className="categorySection">
          <h2 className="h2">Browse by Category</h2>
          <div className="CardGrid">
          <Link to="../pages/category/action">
            <div className="Card">
              <img src={ActionImage} alt="Action" title="Artist: - From FlatIcon" />
              <h3>Action</h3>
            </div>
          </Link>
          <Link to="../pages/category/adventure">
            <div className="Card">
              <img src={AdventureImage} alt="Adventure" title="Artist: - From FlatIcon" />
              <h3>Adventure</h3>
            </div>
          </Link>
          <Link to="../pages/category/arcade">
            <div className="Card">
              <img src={ArcadeImage} alt="Arcade" title="Artist: - From FlatIcon" />
              <h3>Arcade</h3>
            </div>
          </Link>
          <Link to="../pages/category/board">
            <div className="Card">
              <img src={BoardImage} alt="Board" title="Artist: - From FlatIcon" />
              <h3>Board</h3>
            </div>
          </Link>
          <Link to="../pages/category/casual">
            <div className="Card">
              <img src={CasualImage} alt="Casual" title="Artist: - From FlatIcon" />
              <h3>Casual</h3>
            </div>
          </Link>
          <Link to="../pages/category/education">
            <div className="Card">
              <img src={EducationImage} alt="Education" title="Artist: - From FlatIcon" />
              <h3>Education</h3>
            </div>
          </Link>
          <Link to="../pages/category/music">
            <div className="Card">
              <img src={MusicImage} alt="Music" title="Artist: - From FlatIcon" />
              <h3>Music</h3>
            </div>
          </Link>
          <Link to="../pages/category/racing">
            <div className="Card">
              <img src={RacingImage} alt="Racing" title="Artist: - From FlatIcon" />
              <h3>Racing</h3>
            </div>
          </Link>
          <Link to="../pages/category/simulator">
            <div className="Card">
              <img src={SimulationImage} alt="Simulation" title="Artist: - From FlatIcon" />
              <h3>Simulation</h3>
            </div>
          </Link>
          <Link to="../pages/category/sport">
            <div className="Card">
              <img src={SportImage} alt="Sport" title="Artist: - From FlatIcon" />
              <h3>Sport</h3>
            </div>
          </Link>
          <Link to="../pages/category/strategy">
            <div className="Card">
              <img src={StrategyImage} alt="Strategy" title="Artist: - From FlatIcon" />
              <h3>Strategy</h3>
            </div>
          </Link>
          <Link to="../pages/category/shooting">
            <div className="Card">
              <img src={ShootingImage} alt="Shooting" title="Artist: - From FlatIcon" />
              <h3>Shooting</h3>
            </div>
          </Link>
          </div>
      </section>
            
     <RequestForm />
     <TheEnd />
        </main>
    )
}
export default Category;
