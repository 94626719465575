import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";  
import gamepadlogo from "../img/gamepad.webp";

export function Header() {
  /* Open */
  let openNav = () => {
    document.querySelector("#myNav").style.height = "100%";
  };

  /* Close */
  let closeNav = () => {
    document.querySelector("#myNav").style.height = "0%";
  };

  return (
    <header className="Header">
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="One Men Studio .com" />
        <meta name="HandheldFriendly" content="True" />
        <meta name="MobileOptimized" content="320" />
        <meta name="keywords" content="controller compatible games, android Vr game, tablet games, best controller, vr headset, best tablet" />
      </Helmet>
      <Link to="/">
        <div className="logo">
          <img src={gamepadlogo} alt="Logo" />
          <h1>FriendlyAPK</h1>
        </div>
      </Link>

      <nav>
        <span onClick={openNav} className="barNav">
          &#9776;
        </span>
        <div id="myNav" className="overlay">
          <span className="closebtn" onClick={closeNav}>
            &times;
          </span>
          <ul className="overlay-content">
            <Link title="Discover" to="/" onClick={closeNav}>
              <li>Discover</li>
            </Link>
            <Link title="Free" to="../pages/free" onClick={closeNav}>
              <li>Free Games</li>
            </Link>
            <Link title="Controller" to="../pages/Controller" onClick={closeNav}>
              <li>Controller Games</li>
            </Link>
            <Link title="Tablet" to="../pages/Tablet" onClick={closeNav}>
              <li>Tablet Games</li>
            </Link>
            <Link title="VR" to="../pages/VR" onClick={closeNav}>
              <li>VR Games</li>
            </Link>
            <Link title="GOAT" to="../pages/GOAT" onClick={closeNav}>
              <li>GOAT Games</li>
            </Link>
            <Link title="Best" to="../pages/affiliate/controller" onClick={closeNav}>
              <li>Best Controller To Buy</li>
            </Link>
            <Link title="Gaming" to="../pages/affiliate/tablet" onClick={closeNav}>
              <li>Best Tablet To Buy</li>
            </Link>
            <Link title="VR" to="../pages/affiliate/vr" onClick={closeNav}>
              <li>Best VR Headset To Buy</li>
            </Link>
            <Link title="Category" to="../pages/Category" onClick={closeNav}>
              <li>Game Categories</li>
            </Link>
            <a href="https://blog.friendlyapk.com" target="_blank" rel="noreferrer noopener">
              <li>Blog</li>
              </a>
            <Link title="About" to="../pages/About" onClick={closeNav}>
              <li>About Us</li>
            </Link>
          </ul>
        </div>
      </nav>
    </header>
  );
}
export default Header;
