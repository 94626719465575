import React, { useState } from "react";
import "./App.css";
import Axios from "axios";
import { Link } from "react-router-dom";
import Crowed from "./img/crowd.webp"
function RequestForm() {
  const [gameName, setGameName] = useState("");
  const [gameUrl, setGameUrl] = useState("");
  const [yourName, setYourName] = useState("");
  const [yourEmail, setYourEmail] = useState("");
  const [thanks, setThanks] = useState(false);

  const submitRequest = (event) => {
    if(gameName !== ''){
      Axios.post("http://server.friendlyapk.com/requestForm", {
            gameName: gameName,
            gameUrl: gameUrl,
            yourName: yourName,
            yourEmail: yourEmail,
          }).then(() => {
            setThanks(true);
          });
          event.preventDefault();
          event.target.reset();
    }else{
      event.preventDefault();
    }
    
  };

  return (
    <section className="RequestGame">
      <div>
        <img src={Crowed} alt="Community is Awesome" title="Community is Awesome" />
        <h3>Request your favorite Game</h3>
        <Link to="/pages/faq" className="btn1">
          
          how to Request
        </Link>
      </div>

      <form className="InsertFormGame" onSubmit={submitRequest} action="../RequestForm">
        <label htmlFor="gamename">Game Name</label>
        <input
          type="text"
          name="gamename"
          id=""
          onChange={(e) => {
            setGameName(e.target.value);
          }}
        />

        <label htmlFor="gameurl">PlayStore Url*</label>
        <input
          type="url"
          name="gameurl"
          id=""
          placeholder="Easy to locate game"
          onChange={(e) => {
            setGameUrl(e.target.value);
          }}
        />

        <label htmlFor="yourname">Your Name*</label>
        <input
          type="text"
          name="yourname"
          id=""
          placeholder="Let me know you to thank you!"
          onChange={(e) => {
            setYourName(e.target.value);
          }}
        />

        <label htmlFor="youremail">Your Email*</label>
        <input
          type="email"
          name="youremail"
          placeholder="To notify you, when your game is added!"
          onChange={(e) => {
            setYourEmail(e.target.value);
          }}
        />
        <p> * We do not store any of your persoanl data on our site. Email and name is optional and to notify you, if your game is added to the site or not for given reason.</p>
        <p>
          
          * By submiting this form you agree to acknowlegde and accept our all <Link to="../pages/privacy">privacy</Link>, <Link to="../pages/disclaimer"> disclaimers </Link> ,
          <Link to="../pages/termsconditions"> terms & conditions </Link> .
        </p>
        <button type="submit">Add My Game</button>
      </form>
      
      {thanks ? <div className="alertbox"> <p>We will be in touch in 2 weeks!</p> <button className="btn1" onClick={() => {setThanks(false);}}>Okay</button> </div> : <div></div> }
    </section>
  );
}
export default RequestForm;
